import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    ImageInput,
    ImageField,
    required
} from 'react-admin';

import { Grid } from '@mui/material';

import { GolferSelector } from '../components';

export const EventCreate = () => (
    <Create>
        <SimpleForm warnWhenUnsavedChanges>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextInput source="title" fullWidth validate={[required()]} />
                    <TextInput source="subtitle" fullWidth />
                    <TextInput source="venue" fullWidth />
                    <TextInput source="location" fullWidth />
                    <DateInput source="video_date" />
                    <TextInput source="content" label="Description" multiline={true} fullWidth sx={{ '& textarea': {minHeight: 100}}} />
                </Grid>

                <Grid item xs={6}>
                    <GolferSelector source="golfer" />
                    <ImageInput
                        source="thumbnail"
                        label="Image"
                        accept="image/*"
                        placeholder="Drag/Drop an image or click to browse files. Image dimensions: 400x280"
                        validate={[required()]}
                        >
                        <ImageField source="url" sx={{'& img': { maxWidth: '100%', maxHeight: '300px' }}} fullWidth />
                    </ImageInput>
                </Grid>

            </Grid>
        </SimpleForm>
    </Create>
);