import {
    Edit,
    SimpleForm,
    TextInput,
    required

} from 'react-admin';

import { Grid } from '@mui/material';
import { GolferTitle } from '.';

export const GolferEdit = () => (
    <Edit title={<GolferTitle />} mutationMode="pessimistic" >
        <SimpleForm warnWhenUnsavedChanges>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextInput source="name" fullWidth validate={[required()]} />
                </Grid>
            </Grid>

        </SimpleForm>
    </Edit>
);