import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    DateTimeInput,
    ImageInput,
    FileInput,
    required,
    TextField,
    ImageField,
    useReference,
    Loading,
    FormDataConsumer

} from 'react-admin';

import { Grid } from '@mui/material';

import {
    VideoTitle,
    VideoPlayer,
    recordStatusOptions,
    UserReferenceSelector,
    UserImportFromCSV,
    EventSelector,
    VideoEditDetailsFields
} from '.';



export const VideoCreate = () => (
    <Create title={<VideoTitle />} mutationOptions={{ mutationMode: 'pessimistic' }}>
        <SimpleForm warnWhenUnsavedChanges>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <EventSelector />
                    <hr />
                    <VideoEditDetailsFields />
                </Grid>


                <Grid item xs={4}>
                    <FileInput
                        source="video"
                        label="Video"
                        accept="video/*"
                        >
                        <VideoPlayer source="url" />
                    </FileInput>
                </Grid>

                <Grid item xs={4}>
                    <UserReferenceSelector source="references" />
                    <UserImportFromCSV target="references" />
                </Grid>

                <Grid item xs={6}>
                    <SelectInput source="status" choices={recordStatusOptions} defaultValue={"publish"} />
                    &nbsp;
                    <DateTimeInput source="publish_date" />
                </Grid>


            </Grid>

        </SimpleForm>
    </Create>
);

