import {
    Create,
    SimpleForm,
    TextInput,
    required
} from 'react-admin';

import { Grid } from '@mui/material';

export const GolferCreate = () => (
    <Create>
        <SimpleForm warnWhenUnsavedChanges>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextInput source="name" fullWidth validate={[required()]} />
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);