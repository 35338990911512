import {
	AuthenticationDetails
} from 'amazon-cognito-identity-js';

import CognitoHelper from '../util/cognito-helper';

// Global used during password reset process.
//var sessionUserAttributes;

const AuthProvider = poolConfig => {
	const helper = new CognitoHelper( poolConfig );
	return {
		// Login
		// params: form fields
		login: ({username, password}) => {
			const authDetails = new AuthenticationDetails( {Username: username, Password: password } );
			const cognitoUser = helper.getUser( username );
	
			return new Promise( ( resolve, reject ) => {
				cognitoUser.authenticateUser( authDetails, {
					onSuccess: result => {
						cognitoUser.cacheTokens();
						resolve();
					},
					onFailure: err => {
						reject( err );
					},
					newPasswordRequired: ( attrib, reqAttrib ) => {
						console.log( ' ## New password required', attrib, reqAttrib );
	
						delete attrib.email_verified;
						//sessionUserAttributes = attrib;
	
						// Ugly, ugly, ugly hack
						const newPassword = prompt( 'New password?' );
						if ( newPassword ) {
							cognitoUser.completeNewPasswordChallenge( newPassword, { name: attrib.email }, {
								onSuccess: () => {
									cognitoUser.cacheTokens();
									resolve();
								},
								onFailure: (err) => {
									console.log( 'Rejected: ', err );
									reject( err.message );
								}
							});
	
						} else {
	
							// This should initiate change password UI?
							//const history = useHistory();
							//history.push('/passwordreset');
		
							reject( { message: 'You must change your password to continue' } );
						}
					}
				});
			});
		},
	
		// Logout
		logout: () => {
			const user = helper.getCurrentUser();
			if ( user ) {
				user.signOut();
			}
			return Promise.resolve();
		},
	
		// When the dataProvider returns an error, check if this is an authentication error
		checkError: ({status}) => {
			//console.log( 'Checking error', status );
			if ( status === 401 || status === 403 ) {
				const user = helper.getCurrentUser();
				if ( user ) {
					user.signOut();
				}
				return Promise.reject();
			} else {
				return Promise.resolve();
			}
		},
	
		checkAuth: ( auth ) => new Promise((resolve,reject) => {
			const user = helper.getCurrentUser();
			if ( user ) {
				user.getSession( (err, session ) => {
					err ? reject() : resolve();
				});
			} else {
				reject();
			}
		}),
	
		getIdentity: ( params ) => {
			const user = helper.getCurrentUser();

			if ( user ) {
				const ident = {
					id: user.getUsername(),
					extra: {}
				};
				//helper.getUserAttributes( user ).then( console.log )
				return helper.getUserAttributes( user ).then( result => {
					for( var i=0; i < result.length; i++ ) {
						switch( result[i].getName() ) {
							case 'name':
								ident.fullName = result[i].getValue();
								break;
							default:
								ident.extra[result[i].getName() ] = result[i].getValue();
						}
					}

					return ident;
				});
					
			} else {
				return Promise.reject();
			}
		},
	
		getPermissions: () => Promise.resolve()
	};
};

export default AuthProvider;