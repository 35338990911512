import {
    Admin,
    Resource,
    CustomRoutes
} from 'react-admin';

import { Route } from 'react-router-dom';
import AuthProvider from './provider/AuthProvider';
import DataProvider from './provider/DataProvider';

import {
    default as GolfLayout,
    User,
    Video,
    Event,
    Golfer,
    Dashboard,
    ComingSoon
} from './layout';

//import PasswordReset from './PasswordReset';

import config from './config';


const App = () => (
    <Admin
        dashboard={Dashboard}
        title="KPMG Golf"
        layout={GolfLayout}
        dataProvider={DataProvider(config.GolfAdminApi.EndpointUrl)}
        authProvider={AuthProvider(config.CognitoUserPool)}
        requireAuth disableTelemetry
        >
            <Resource name="golfers" list={Golfer.List} edit={Golfer.Edit} create={Golfer.Create} options={{label: 'Golf Pro'}} />
            <Resource name="events" list={Event.List} edit={Event.Edit} create={Event.Create} />
            <Resource name="videos" list={Video.List} create={Video.Create} edit={Video.Edit} />
            <Resource name="users" list={User.List} edit={User.Edit} create={User.Create} />

            <CustomRoutes>
                <Route path="/options" element={<ComingSoon />} />
                <Route path="/passwordreset" element={<ComingSoon />} />    
            </CustomRoutes>
    </Admin>
);

export default App;