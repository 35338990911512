import * as React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Title } from 'react-admin';

const ComingSoon = () => (
    <Card>
        <Title title="Coming Soon" />
        <CardContent>
            <h1>Coming Soon</h1>
        </CardContent>
    </Card>
);

export default ComingSoon;