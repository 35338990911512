import { Button } from 'react-admin';
import {
    
    Menu,
    MenuItem,
    ListItemText,
    ListItemIcon,
    CircularProgress,
    Badge,
    Typography

} from '@mui/material';

import UploadIcon from '@mui/icons-material/Upload';

import { useState, useCallback } from 'react';


const children = (
    <MenuItem>
        <ListItemIcon><CircularProgress size={20} /></ListItemIcon>
        <ListItemText>Uploading Item 1</ListItemText>
    </MenuItem>
);

const UploadStatusMenu = (props) => {
    const [ anchorEl, setAnchorEl ] = useState(null);
    const handleUploadMenu = event => setAnchorEl(event.currentTarget);
    const handleUploadClose = useCallback(() => setAnchorEl(null), []);
    const open = Boolean(anchorEl);
    const uploads = 0;

    // @todo: Disabled for now.
    return null;

    return (

        <>
            <Button
                color="inherit"
                onClick={handleUploadMenu} >
                    <Badge
                        badgeContent={uploads}
                        color="warning"
                        showZero
                        >
                        <UploadIcon />
                    </Badge>
            </Button>
            
            <Menu
                open={open}
                onClose={handleUploadClose}
                anchorEl={anchorEl}
                >
                {children}
            </Menu>

        </>
    );

};


export default UploadStatusMenu;