import { Box, Typography } from '@mui/material';
import { sanitizeFieldRestProps } from 'react-admin';
import { useRecordContext } from 'react-admin';


export const VideoPlayer = (props) => {
    const { className, emptyText, source, src, title, ...rest } = props;
    const record = useRecordContext(props);
    const sourceValue = record[source];
    const titleValue = record.title || '';

    return (
        <Box className={className} {...sanitizeFieldRestProps(rest)}
            sx={{
                maxWidth: '80%',
                verticalAlign: 'top',
                display: 'inline-block'
            }}
            >
            <div>{titleValue}</div>
            <video controls preload="meta"
                style={{maxWidth: '100%', height: 'auto'}}
                >
                <source type="video/mp4" src={sourceValue} />
            </video>
        </Box>
    );
}

export default VideoPlayer;
