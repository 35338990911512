import {
    useRecordContext
} from 'react-admin';

import { GolferList } from './list';
import { GolferCreate } from './create';
import { GolferEdit } from './edit';

export const GolferTitle = () => {
    const record = useRecordContext();
    const title = record && record.name ? record.name : '(no title)';
    return record ? <span>Golf Pro: {title}</span> : '';
};


const Golfer = {
    List: GolferList,
    Edit: GolferEdit,
    Create: GolferCreate
};

export default Golfer;
