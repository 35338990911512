import {
	CognitoUserPool,
	CognitoUser
} from 'amazon-cognito-identity-js';

class CognitoHelper {

    userPool = null;

    constructor( poolConfig ) {
        this.userPool = new CognitoUserPool( poolConfig );
    }

    getUser( username ) {
        const userData = {
            Username: username,
            Pool: this.userPool
        };

        return new CognitoUser( userData );
    }

    // Returns Promise
    getUserSession( user ) {
        return new Promise( ( resolve, reject ) => {
            user.getSession( ( err, session ) => {
                err ? reject( err ) : resolve( session );
            })
        });
    }
    
    // Returns Promise
    async getUserAttributes( user, asObject ) {
        if ( !user ) {
            user = this.getCurrentUser();
        }

        // Session needs to be called before getting attributes to ensure local credentials are loaded.
        await this.getUserSession( user );
        
        return new Promise( (resolve, reject) => {
            user.getUserAttributes( ( err, result ) => {
                if ( err ) {
                    reject( err );
                }

                const val = asObject ? this.getUserAttributesObject( result ) : result;
                resolve( val );
            });
        });
        

        /*
        return new Promise( ( resolve, reject ) => {
            this.getUserSession( user ).then( () => {
                user.getUserAttributes( ( err, result ) => {
                    err ? reject( err ) : resolve( result );
                });
            });
        });

        */
    }

    async getUserAttributesObject( attr ) {
        if ( !attr ) {
            attr = await this.getUserAttributes();
        }

        const result = {};
        for( var i=0; i < attr.length; i++ ) {
            result[ attr[i].getName() ] = attr[i].getValue();
        }

        return result;
    }

    getCurrentUser() {
        return this.userPool.getCurrentUser();
    }

    async getCurrentUserSession() {
        return this.getUserSession( this.getCurrentUser() );
    }

    // Used by Identity Provider for AWS services like S3 uploda.
    async getCognitoCredentials() {
        const session = await this.getCurrentUserSession();
        return {
            token: session.getIdToken().getJwtToken(),
            session: session
        };
    }

}

export default CognitoHelper;

