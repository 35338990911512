import { AppBar, AppBarClasses as classes } from 'react-admin';
import Typography from '@mui/material/Typography';
import UploadStatusMenu from './upload-status-menu';

const GolfAppBar = (props) => {

    return (
        <AppBar
            sx={{
                "& .RaAppBar-title": {
                    flex: 1,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                },
            }}
            {...props} >

            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />

            {/*
            <Typography variant="h6" color="inherit">KPMG Golf</Typography>
            */}

            <span className={classes.spacer} />

            <UploadStatusMenu />

        </AppBar>
    );

}


export default GolfAppBar;