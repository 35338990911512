import {
    List,
    Datagrid,
    TextField,
    DateField,
    TextInput,
    SearchInput,
    TopToolbar,
    FilterButton,
    CreateButton,
    WrapperField,
    ReferenceField
} from 'react-admin';

/*
Event
- Title
- Subtitle
- Date
- Venue
- Location
- Golfer
- Users (assign to show on their feed
- Image (top image)
*/

const filterOptions = [
    <TextInput label="Search" source="q" alwaysOn />,
];

const listFilters = [
    <SearchInput source="q" alwaysOn />
];

const ListActions = () => (
    <TopToolbar>
        <FilterButton filters={filterOptions} />
        <CreateButton />
    </TopToolbar>
);

// Set UTC only on list view.
const dateOptions = { dateStyle: 'medium', timeZone: 'UTC' };
const dateTimeOptions = { dateStyle: 'medium', timeStyle: 'short' };


export const EventList = props => (
    <List
        actions={<ListActions />}
        filters={listFilters}
        sort={{ field: 'video_date', order: 'DESC' }}
        >
        <Datagrid rowClick="edit">
            <TextField source="title" />
            <TextField source="subtitle" />
            <TextField source="venue" />
            <TextField source="location" />
            <DateField source="video_date" options={dateOptions} />
            <ReferenceField label="Golf Pro" source="golfer" reference="golfers">
                <TextField source="name" />
            </ReferenceField>
            <DateField source="publish_date" label="Published" showTime={true} options={dateTimeOptions} />
            <DateField source="modified_date" label="Updated" showTime={true} options={dateTimeOptions} />
        </Datagrid>
    </List>
);
