// Data Provider
import { stringify } from 'query-string';
import S3CognitoUploader from '../util/s3-cognito-uploader';
import authorizedHttpClient from '../util/authorized-http-client';
import config from '../config';
import { HttpError } from 'react-admin';

function DataProvider( apiUrl, httpClient ) {
    if ( !httpClient ) {
        httpClient = authorizedHttpClient;
    }

    const provider = {
        getList: async (resource, params) => {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;

            const query = {
                sort: JSON.stringify([field, order]),
                filter: JSON.stringify(params.filter),
                page,
                perPage
            };

            const url = `${apiUrl}/${resource}?${stringify(query)}`;
            const { json } = await httpClient(url);

            return json;
        },

        getOne: async (resource, params) => {
            return httpClient(`${apiUrl}/${resource}/${encodeURIComponent( params.id )}`).then(({ json }) => ({ data: json.data }))
        },

        getMany: async (resource, params) => {
            const query = {
                filter: JSON.stringify({ id: params.ids }),
            };
            const url = `${apiUrl}/${resource}?${stringify(query)}`;
            return httpClient(url).then(({ json }) => ({ data: json.data }));
        },

        getManyReference: async (resource, params) => {
            console.log( 'Calling getManyReference' );
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;

            const query = {
                sort: JSON.stringify([field, order]),
                page, perPage,
                //range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
                filter: JSON.stringify( params.filter ),
            };

            const url = `${apiUrl}/${resource}?${stringify(query)}`;

            return httpClient(url).then(({ headers, json }) => {
                return {
                    data: json.data,
                    total: parseInt( json.total, 10 )
                };
            });
        },

        update: async (resource, params) => {

            await maybeHandleUpload( resource, params );

            const { json } = await httpClient(`${apiUrl}/${resource}/${encodeURIComponent(params.id)}`, {
                method: 'PUT',
                body: JSON.stringify(params.data)
            });
            
            if ( json.error ) {
                throw new Error( json.error );
            }

            if ( !json.data ) {
                throw new Error( 'An unknown error occurred.' );
            }

            return { data: json.data };
        },

        create: async (resource, params) => {

            await maybeHandleUpload( resource, params );

            const { json } = await httpClient(`${apiUrl}/${resource}`, {
                method: 'POST',
                body: JSON.stringify(params.data),
            });
            
            if ( json.error ) {
                throw new Error( json.error );
            }

            if ( !json.data.id ) {
                throw new Error( 'There was a problem creating the record.' );
            }

            if ( !json.data ) {
                throw new Error( 'An unknown error occurred.' );
            }

            return {
                data: { ...params.data, id: json.data.id },
            };
        },

        delete: async (resource, params) => {
            const data = await httpClient(`${apiUrl}/${resource}/${encodeURIComponent(params.id)}`, {
                method: 'DELETE',
                headers: new Headers({
                    'Content-Type': 'text/plain',
                }),
            });

            if ( data.error ) {
                throw new HttpError( data.error );
            }

            return { data };
        },

        // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        deleteMany: (resource, params) => {
            return Promise.all(
                params.ids.map(id =>
                    httpClient(`${apiUrl}/${resource}/${encodeURIComponent(id)}`, {
                        method: 'DELETE',
                        headers: new Headers({
                            'Content-Type': 'text/plain',
                        }),
                    })
                )
            ).then(responses => ({
                data: responses.map(({ json }) => json.id)
            }));
        }
    };

    // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
    provider.updateMany = (resource, params) => {
        const updates = params.ids.map(id => provider.update( resource, { id, data: params.data } ) );    
        return Promise.all( updates )
            .then(responses => ({ data: responses.map(({ json }) => json.id) }));
    };
    
    return provider;
}


const maybeHandleUpload = async ( resource, params ) => {
    if ( ! [ 'videos', 'events' ].includes( resource ) ) {
        return;
    }

    const uploads = await handleUploads( params );
    Object.keys( uploads ).forEach( k => {
        params.data[k] = uploads[k];
    });
}



const handleUploads = async (params) => {
    const fileProps = [ 'thumbnail', 'video' ];
    var uploads = [];
    const cognitoUploader = new S3CognitoUploader( config );

    for ( const p of fileProps ) {
        const f = params.data[p];
        delete params.data[p];
        if ( f && f.rawFile instanceof File ) {
            const cfg = {
                tags: await cognitoUploader.getTags( f.rawFile )
            };

            const up = await cognitoUploader.upload( f.rawFile, cfg );

            // @todo: If this received a reference to the preview component then we could identify percent completed on the UI.
            up.uploader.on( 'httpUploadProgress', progress => {
                const percent = (progress.loaded / progress.total ) * 100;
                console.log( 'progress:', Math.round( percent ), '%', 'part', progress.part );
            });

            // Tag response object with source field for tracking purposes.
            const obj = up.uploader.done().then( (d) => {
                d._uploadField = p;
                d._uploadConfig = up.config;
                return d;
            } );

            uploads.push( obj );
        }
    }

    return Promise.all( uploads ).then( (files) => {
      const result = {};
      
      for ( const f of files ) {
        result[f._uploadField] = f._uploadConfig.params.Key;
      }

      return result;
    });
};

export default DataProvider;