import { Button } from 'react-admin';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const ImportUserButton = (props) => (
    <Button label="Import Users"
        disabled
        onClick={handleClick}
        {...props} >
        <UploadFileIcon />
    </Button>
    
);

const handleClick = () => {

}

export default ImportUserButton;
export { ImportUserButton };