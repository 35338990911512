// Simple file uploade button based on:
// https://medium.com/web-dev-survey-from-kyoto/how-to-customize-the-file-upload-button-in-react-b3866a5973d8


import React from 'react';
import { Button } from '@mui/material';

const FileUploader = props => {
    const { handleFile } = props;

    const fileInput = React.useRef( null );

    const handleClick = event => {
        fileInput.current.click();
    };

    const handleChange = event => {
        const FileUploaded = event.target.files[0];
        handleFile && handleFile( FileUploaded );
    };

    const label = props.label || 'Upload a file';
    const accept = props.accept || '*';

    return (
        <>
            <Button onClick={handleClick}>
                {label}
            </Button>
            <input type="file" ref={fileInput} onChange={handleChange} style={{display:'none'}} accept={accept} />
        </>
    );
};

export default FileUploader;
