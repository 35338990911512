import {
    Grid,
    Card,
    CardContent,
    Paper
} from '@mui/material';

import { Title } from 'react-admin';
import { DisplayRecordCount } from './components/display-record-count';
import pkg from '../../package.json';


const Dashboard = () => {
    return (
        <>
            <Title title={`KPMG Golf Admin v${pkg.version}`} />
            <Grid container spacing={2} padding={2}>
                <Grid item xs={3}>
                    <Paper>
                        <DisplayRecordCount />
                    </Paper>

                </Grid>

            </Grid>
            </>
    );
};

export default Dashboard;