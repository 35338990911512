import { Layout } from 'react-admin';
import { GolfMenu } from './common/menu.js';
import { default as GolfAppBar } from './common/appbar.js';

export { default as Video } from './video';
export { default as User } from './user';
export { default as Event } from './event';
export { default as Golfer } from './golfer';
export { default as Dashboard } from './dashboard';
export { default as ComingSoon } from './common/coming-soon';

const GolfLayout = (props) => <Layout
    {...props}
    menu={GolfMenu}
    appBar={GolfAppBar}
/>;

export default GolfLayout;