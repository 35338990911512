//import { useAuthProvider } from 'react-admin';
import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import CognitoHelper from './cognito-helper';
import config from '../config';

import { sanitize_string } from '.';

// @todo: This may not be needed once the upload parameters come from an API request.
const getUploadPath = (file) => {
    const now = new Date();
    const month = now.getMonth() + 1;
    const pathPrefix = [
        'content',
        now.getFullYear(),
        month.toString().padStart(2, '0'),
        now.getDate().toString().padStart(2, '0')
    ].join('/') + '/';

    let filename = sanitize_string( file.name.replace( '..', '' ) );
    const datestamp = '-' + now.valueOf().toString().slice( -10 );

    const dot = filename.lastIndexOf( '.' );
    let ext = filename.substring( dot );
    filename = filename.substring( 0, dot ) + datestamp + ext;

    return pathPrefix + filename;
};


class S3CognitoUploader {

    region = 'us-east-1';
    logins = {};

    /*
    config parameter:
         - userPool
         - identityPool
         - cognitoId
         - userToken
    */
    constructor( config ) {
        if ( config ) {
            this.userPool = config.CognitoUserPool;
            this.identityPool = config.CognitoIdentityPool;
            this.helper = new CognitoHelper( this.userPool );
        }
    }

    // Pass other options like tags through config argument.
    async upload( file, config ) {
        const client = await this.getClient();
        const uploadConfig = {
            client,
            params: await this.getUploadParameters( file )
        };

        if ( config ) {
            Object.assign( uploadConfig, config );
        }

        // https://github.com/aws/aws-sdk-js-v3/blob/main/lib/lib-storage/src/Upload.ts
        return {
            uploader: new Upload( uploadConfig ),
            config: uploadConfig
        };

        /*
        httpUploadProgress callback param progress properties include:
         - loaded (bytes)
         - total (bytes)
         - part (int)
         - Key (string)
         - Bucket (string)

        uploader.on( 'httpUploadProgress', progress => {
            const percent = (progress.loaded / progress.total ) * 100;
            console.log( 'progress:', percent, '%', progress );
        });

        // Promise resolves as soon as upload is completed or aborted.
        await uploader.done();


        */
    }

    async getClient() {
        const credentials = await this.cognitoCredentialProvider();
        return new S3Client( { region: this.region, credentials } );
    }

    async cognitoCredentialProvider() {

        const cred = await this.helper.getCognitoCredentials()
        this.logins = { [config.CognitoIdentityPool.CognitoId] : cred.token };

        return fromCognitoIdentityPool({
            clientConfig: { region: this.region },
            identityPoolId: this.identityPool.IdentityPoolId,
            logins: this.logins
        });
    }

    // @todo: Replace with API call to determine parameters.
    async getUploadParameters( file ) {
        return {
            Bucket: 'golfmedia',
            Key: getUploadPath(file),
            Body: file
        };    
    }

    async getTags( file, cognitoUser ) {
        const tags = [
            { Key: 'originalFilename', Value: file.name }
        ];
    
        cognitoUser = cognitoUser || this.helper.getCurrentUser();

        if ( cognitoUser ) {
            const attr = await this.helper.getUserAttributes( cognitoUser, true );
            tags.push( { Key: 'uploaderUserId', Value: cognitoUser.getUsername() } );
            tags.push( { Key: 'uploaderUserEmail', Value: attr.email } );
        };
    
        return tags;    
    }
}

export default S3CognitoUploader;