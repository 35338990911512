import {
    useRecordContext
} from 'react-admin';

/*
function printinfo( record ) {
    let info = '';
    if ( record ) {
        info += record.first_name;
        info += record.last_name;

        if ( record.email ) {
            info += ' (' + record.email + ')';
        }

    }

    return info.trim();
}
*/

export const UserNameMatchSuggestion = ( filter, choice ) => 
    choice.email.match( filter ) || choice.first_name.match( filter ) || choice.last_name.match( filter );

export const UserNameInputText = (record) => `${record.first_name} ${record.last_name} (${record.email})`;

export const UserNameAndEmail = () => {
    const record = useRecordContext();
    return <span>{UserNameInputText( record )}</span>;
};

export default UserNameAndEmail;
//export { UserNameAndEmail, UserNameMatchSuggestion, UserNameInputText };