
import {
    ReferenceInput,
    AutocompleteInput,
    required
} from 'react-admin';

const eventTitle = (choice) => {

    return (
        <span>
            {choice.title} {choice.subtitle} ({choice.video_date})<br />
            {choice.venue}
        </span>
    );
}

const inputText = (choice) => `${choice.title} ${choice.subtitle} ${choice.video_date}`;

const matchSuggestion = (filter, choice) => {
    const terms = filter.toLowerCase().split( ' ' );
    const check = [ 'title', 'subtitle', 'venue', 'video_date', 'location' ];

    for ( const k of check ) {
        for ( const t of terms ) {
            if ( choice[k] && choice[k].toLowerCase().includes(t) ) {
                return true;
            }
        }
    }

    return false;
}

export const EventSelector = (props) => {

    return (
        <ReferenceInput source="event" reference="events">
            <AutocompleteInput
                fullWidth
                helperText="Select associated event to populate fields below."
                optionText={eventTitle}
                inputText={inputText}
                matchSuggestion={matchSuggestion}
                optionValue="id"
                noOptionsText="Search for Events"
                shouldRenderSuggestions={(value) => value.trim().length > 2}
                emptyText="No Event Selected"
                emptyValue=""
                validate={required()}
                />
        </ReferenceInput>
    );
};


export default EventSelector;