import {
    List,
    Datagrid,
    TextField,
    NumberField,
    FunctionField,
    DateField,
    TextInput,
    TopToolbar,
    FilterButton,
    CreateButton,
    SearchInput
} from 'react-admin';

import { ImportUserButton } from '../components';

const filterOptions = [
    <TextInput label="Search" source="q" alwaysOn />,
];

const listFilters = [
    <SearchInput source="q" alwaysOn />
];

const ListActions = () => (
    <TopToolbar>
        <FilterButton filters={filterOptions} />
        <CreateButton />
        <ImportUserButton />
    </TopToolbar>
);

const dateTimeOptions = { dateStyle: 'medium', timeStyle: 'short' };

export const UserList = props => (
    <List {...props}
        actions={<ListActions />}
        filters={listFilters}>
        <Datagrid rowClick="edit">
            <TextField source="email" />
            <FunctionField label="Name" sortable={false} render={ record => `${record.first_name } ${record.last_name }`} />
            <NumberField source="video_count" sortable={false} />
            <DateField source="modified_date" showTime sortable={false} options={dateTimeOptions} />
        </Datagrid>
    </List>
);
