import {
    AutocompleteArrayInput,
    ReferenceArrayInput
} from 'react-admin';

import {
    UserNameInputText,
    UserNameMatchSuggestion,
    UserNameAndEmail
} from '../components';

export const UserReferenceSelector = (props) => (
    <ReferenceArrayInput
        {...props}
        reference="users"
        enableGetChoices={({ q }) => (q ? q.length >= 2 : false)}
        >
        <AutocompleteArrayInput fullWidth
            label="Assigned Users"
            noOptionsText="Enter email or name to search"
            inputText={UserNameInputText}
            matchSuggestion={UserNameMatchSuggestion}
            optionText={<UserNameAndEmail />}
            createLabel="Import"
        />
    </ReferenceArrayInput>
);

export default UserReferenceSelector;