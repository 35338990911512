import {
    ArrayField,
    Edit,
    SimpleForm,
    TextInput,
    PasswordInput
} from 'react-admin';

import { Grid, Typography } from '@mui/material';
import {
    UserTitle,
    ShadowUserSelector,
    ShadowUserVideoList,
    VideoRefItem
} from '../components';



export const UserEdit = (props) => {
    
    return (
    <Edit title={<UserTitle />} mutationMode="pessimistic" redirect={false}>

        <SimpleForm warnWhenUnsavedChanges>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextInput source="first_name" fullWidth />
                    <TextInput source="last_name" fullWidth />
                    <TextInput source="email" fullWidth />
                    <PasswordInput source="code" fullWidth data-lpignore="true" />
                </Grid>
                <Grid item xs={4}>
                    <ShadowUserSelector source="shadow_user" />
                </Grid>

                <Grid item xs={4}>

                </Grid>

                <Grid item xs={6}>
                    <Typography variant="h6">Videos</Typography>
                    <ArrayField source="videos">
                        <VideoRefItem />
                    </ArrayField>
                </Grid>

                <Grid item xs={6}>
                    <ShadowUserVideoList />
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
)};