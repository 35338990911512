import {
    Datagrid,
    ImageField,
    WrapperField,
    TextField,
    DateField,
    useRedirect
} from 'react-admin';


const dateOptions = { dateStyle: 'medium' };

export const VideoRefItem = () => {
    const redirect = useRedirect();
    const editVideoClick = ( id ) => {
        redirect( 'edit', 'videos', id );
    };

    return (
        <Datagrid bulkActionButtons={false} rowClick={editVideoClick}>
            <ImageField label="" source="thumbnail_url" sx={{ '& img': { maxWidth: '100px' }}} />
            <WrapperField>
                <TextField source="title" /> <TextField source="subtitle" /><br />
                <TextField source="venue" /><br />
                <TextField source="location" /><br />
                <DateField source="video_date" options={dateOptions} /><br />
            </WrapperField>
        </Datagrid>
    );
};

export default VideoRefItem;