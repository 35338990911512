import {
    Datagrid,
    TextField
} from "react-admin";

import Typography from '@mui/material/Typography';
import { apiRequest } from '../../util/authorized-http-client';

import { useState, useEffect } from 'react';

const getData = async () => {
    const { status, json } = await apiRequest( '' );
    return json.record_counts || [];
};




export const DisplayRecordCount = (props) => {
    let isLoading = false;

    const [ data, setData ] = useState([]);

    useEffect( () => {

        if ( data.length ) {
            return;
        }

        getData().then( response => {
            setData( response );
        });
    });    

    return (
        <>
            <Typography>Record Counts</Typography>

            <Datagrid
                data={data}
                isLoading={isLoading}
                bulkActionButtons={false}
                sort={false}>
                <TextField source="type" />
                <TextField source="count" />
            </Datagrid>
        </>
    );
}

export default DisplayRecordCount;