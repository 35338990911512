import {
    ReferenceInput,
    AutocompleteInput
} from 'react-admin';

const ShadowUserSelector = (props) => {

    return (
        <ReferenceInput
            {...props}
            reference="users"
            sort={{ field: 'email', order: 'ASC' }}
            enableGetChoices={({q}) => { return q && q.length > 4; } }
            >
            <AutocompleteInput optionText="email" optionValue="id"
                fullWidth
                isOptionEqualToValue={(option, value) => {return option.id === value.id;} }
            />
        </ReferenceInput>
    );

}

export default ShadowUserSelector;
export { ShadowUserSelector };