
import {
    ReferenceInput,
    AutocompleteInput
} from 'react-admin';

export const GolferSelector = (props) => {

    return (
        <ReferenceInput source={props.source} reference="golfers">
            <AutocompleteInput
                label="Golf Pro"
                fullWidth
                optionText="name"
                optionValue="id"
                noOptionsText="Search for a Golf Pro"
                emptyText="No Golf Pro Selected"
                emptyValue=""
                disabled={props.disabled}
                />
        </ReferenceInput>
    );
};


export default GolferSelector;