import { fetchUtils } from 'ra-core';
import CognitoHelper from './cognito-helper';
import config from '../config';
const helper = new CognitoHelper( config.CognitoUserPool );

const authorizedHttpClient = async (url, options) => {
    if ( !options ) {
        options = {};
    }

    if ( !options.headers ) {
        options.headers = new Headers();
    }

    // Add credentials header if available.
    const cred = await helper.getCognitoCredentials()
    if ( cred ) {
        options.headers.append( 'Authorization', cred.token );
    }

    return fetchUtils.fetchJson( url, options );
};

const apiRequest = ( path, options ) => {
    const base_url = config.GolfAdminApi.EndpointUrl;
    return authorizedHttpClient( base_url + '/' + path, options );
}
export default authorizedHttpClient;
export { apiRequest };