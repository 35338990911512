import {
    TextInput,
    DateInput,
    BooleanInput,
    useReference,
    TextField,
    Labeled,
    ReferenceField,
    WrapperField,
    useRefresh,
    FunctionField,
    required,
    RecordContextProvider
} from 'react-admin';

import {
    useWatch,
    useFormContext
} from 'react-hook-form';

import { VideoThumb } from './video-thumb';

export const VideoEditDetailsFields = (props) => {
    const { getValues, setValue } = useFormContext();
    const event_id = useWatch({ name: 'event' });
    const override = getValues( 'override_content' );
    const isDisabled = !!event_id;

    const { referenceRecord: data, isLoading, error } = useReference({ reference: 'events', id: event_id } );
    const refresh = useRefresh();
    const fields = [
        'thumbnail_url',
        'title',
        'subtitle',
        'location',
        'venue',
        'video_date',
        'golfer'
    ];

    if ( !override ) {
        fields.push( 'content' );
    }

    let values = {};
    
    if ( event_id && ! ( isLoading && error ) && data ) {
        values = data;
    }

    fields.forEach( k => {
        const v = values && k in values ? values[k] : '';
        setValue( k, v );
    });

    //refresh();
    return (
        <>

            <RecordContextProvider record={data}>
                <TextField source="title" /><br />
                <TextField source="subtitle" /><br />
                <TextField source="venue" /><br />
            </RecordContextProvider>            {/*
            <TextInput source="title" fullWidth disabled={isDisabled} />
            <TextInput source="subtitle" fullWidth disabled={isDisabled} />
            <TextInput source="venue" fullWidth disabled={isDisabled} />
            <TextInput source="location" fullWidth disabled={isDisabled} />
            <DateInput source="video_date" disabled={isDisabled} />
            */}
            <BooleanInput source="override_content" disabled={!isDisabled} label="Override Description" />
            <TextInput source="content" fullWidth disabled={!override} label="Description" multiline={true} sx={{ '& textarea': {minHeight: 100}}} />

        </>
    );
}

export default VideoEditDetailsFields;