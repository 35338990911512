import {
    List,
    Datagrid,
    TextField,
    DateField,
    TextInput,
    SearchInput,
    TopToolbar,
    FilterButton,
    CreateButton,
    WrapperField,
    ReferenceField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    useRecordContext
} from 'react-admin';

import { path } from '../../util';
//import { VideoThumb } from '.';

const dateOptions = { dateStyle: 'medium' };
const dateTimeOptions = { dateStyle: 'medium', timeStyle: 'short' };
const filterOptions = [
    <TextInput label="Search" source="q" alwaysOn />,
];

const listFilters = [
    <SearchInput source="q" alwaysOn />
];

const ListActions = () => (
    <TopToolbar>
        <FilterButton filters={filterOptions} />
        <CreateButton />
    </TopToolbar>
);

const VideoMediaField = (props) => {
    //const { name_source } = props;
    const record = useRecordContext();
    const name = (record && record[props.name_source]) || '';
    const filename = name ? path.basename( name ) : '';

    const labelStyle = {
        display: 'block',
        width: '100%',
        maxWidth: '150px',
        border: 'solid 1px #ccc',
        padding: '2px',
        overflowInline: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: '10px'
    };
    
    return (
        <>
            <img width="120" alt="Video thumbnail" src={record[props.source]} /><br />
            <small className="filename-label"
                title={filename}
                style={labelStyle}
            >{filename}</small>
        </>
    );
};



export const VideoList = props => (
    <List
        actions={<ListActions />}
        filters={listFilters}
        sort={{ field: 'video_date', order: 'DESC' }}
        >
        <Datagrid rowClick="edit">
            <VideoMediaField label="Media" source="thumbnail_url" name_source="video_path" sortable={false} />
            <WrapperField label="Title">
                <TextField source="title" sx={{fontWeight: 'bold'}} /><br />
                <TextField source="subtitle" />
            </WrapperField>
            <WrapperField label="Location">
                <TextField source="venue" /><br />
                <TextField source="location" />
            </WrapperField>
            <DateField source="video_date" options={dateOptions} />
            <ReferenceField source="event" reference="events">
                <TextField source="title" />
            </ReferenceField>
            <ReferenceField source="golfer" reference="golfers">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceArrayField source="references" reference="users" label="Users">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <DateField source="publish_date" showTime={true} options={dateTimeOptions} />
            <DateField source="modified_date" showTime={true} options={dateTimeOptions} />
        </Datagrid>
    </List>
);
