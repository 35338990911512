import { VideoList } from './list';
import { VideoCreate } from './create';
import { VideoEdit } from './edit';

import {
    useRecordContext
} from 'react-admin';

export * from '../components';

const Video = {
    List: VideoList,
    Edit: VideoEdit,
    Create: VideoCreate
};

export const recordStatusOptions = [
    { id: 'publish', name: 'Publish' },
    { id: 'draft', name: 'Draft' }
];

export const dateFormat = { dateStyle: 'medium' };
export const dateTimeFormat = { dateStyle: 'medium', timeStyle: 'short' };

export const VideoTitle = () => {
    const record = useRecordContext();
    const title = record && record.title ? record.title : '(no title)';
    return record ? <span>Video: {title}</span> : '';
};

export default Video;
