import {
    ArrayField,
    useReference
} from 'react-admin';

import { Typography } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { VideoRefItem } from '.';

export const ShadowUserVideoList = () => {
    const shadow_user = useWatch( { name: 'shadow_user' } );
    const { referenceRecord: data, isLoading, error } = useReference( { reference:'users', id: shadow_user } );

    if ( shadow_user && !( isLoading || error ) ) {
        return (
            <>
                <Typography variant="h6">Shadow User Videos</Typography>
                <ArrayField source="videos" record={data || {} }>
                    <VideoRefItem />
                </ArrayField>
            </>
        );
    }

    return null;
};

export default ShadowUserVideoList;