import {
    Datagrid,
    TextField,
    DateField,
    ArrayField,
    ImageField,
    WrapperField,

    Create,
    SimpleForm,
    TextInput,
    PasswordInput,
} from 'react-admin';

import { Grid, Typography } from '@mui/material';

import {
    ShadowUserSelector,
    ShadowUserVideoList,
    VideoRefItem
} from '../components';

const dateOptions = { dateStyle: 'medium' };


export const UserCreate = () => (
    <Create mutationMode="pessimistic">
        <SimpleForm warnWhenUnsavedChanges>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextInput source="first_name" fullWidth />
                    <TextInput source="last_name" fullWidth />
                    <TextInput source="email" fullWidth />
                    <PasswordInput source="code" fullWidth />
                </Grid>
                <Grid item xs={4}>
                    <ShadowUserSelector source="shadow_user" />
                </Grid>
                <Grid item xs={4}>
                    
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="h6">Videos</Typography>
                    <ArrayField source="videos">
                        <VideoRefItem />
                    </ArrayField>
                </Grid>

                <Grid item xs={6}>
                    <ShadowUserVideoList />
                </Grid>
            </Grid>

        </SimpleForm>
    </Create>
);