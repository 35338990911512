import {
    Edit,
    SimpleForm,
    SelectInput,
    ImageInput,
    FileInput,
    required,
    ImageField,
    DateTimeInput,
} from 'react-admin';

import { Grid } from '@mui/material';

import {
    VideoTitle,
    VideoPlayer,
    recordStatusOptions,
    UserReferenceSelector,
    UserImportFromCSV,
    EventSelector,
    VideoEditDetailsFields
} from '.';

export const VideoEdit = () => (
    <Edit title={<VideoTitle />} mutationMode="pessimistic" redirect={false}>
        <SimpleForm warnWhenUnsavedChanges>
            <Grid container spacing={2}>

                <Grid item xs={4}>
                    <EventSelector />
                    <hr />
                    <VideoEditDetailsFields />
                </Grid>

                <Grid item xs={4}>
                    <FileInput
                        source="video"
                        label="Video"
                        accept="video/*"
                        >
                        <VideoPlayer source="url" />
                    </FileInput>
                </Grid>

                <Grid item xs={4}>
                    <UserReferenceSelector source="references" />
                    <UserImportFromCSV target="references" />
                </Grid>

                <Grid item xs={6}>
                    <SelectInput source="status" choices={recordStatusOptions} defaultValue={"publish"} />
                    &nbsp;
                    <DateTimeInput source="publish_date" />

                </Grid>

            </Grid>

        </SimpleForm>
    </Edit>
);