import {
    List,
    Datagrid,
    TextField,
    TextInput,
    SearchInput,
    TopToolbar,
    FilterButton,
    CreateButton
} from 'react-admin';


const filterOptions = [
    <TextInput label="Search" source="q" alwaysOn />,
];

const listFilters = [
    <SearchInput source="q" alwaysOn />
];

const ListActions = () => (
    <TopToolbar>
        <FilterButton filters={filterOptions} />
        <CreateButton />
    </TopToolbar>
);

export const GolferList = props => (
    <List
        actions={<ListActions />}
        filters={listFilters}
        >
        <Datagrid rowClick="edit">
            <TextField source="name" />
        </Datagrid>
    </List>
);
