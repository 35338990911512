import { UserList } from './list';
import { UserCreate } from './create';
import { UserEdit } from './edit';

const User = {
    List: UserList,
    Edit: UserEdit,
    Create: UserCreate
};

export default User;