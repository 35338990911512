import {
    useRecordContext
} from 'react-admin';

import { EventList } from './list';
import { EventCreate } from './create';
import { EventEdit } from './edit';


export const EventTitle = () => {
    const record = useRecordContext();
    const title = record && record.title ? record.title : '(no title)';
    return record ? <span>Event: {title}</span> : '';
};


const Event = {
    List: EventList,
    Edit: EventEdit,
    Create: EventCreate
};

export default Event;
