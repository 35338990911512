import * as React from 'react';
import { DashboardMenuItem, Menu, MenuItemLink } from 'react-admin';
import EventIcon from '@mui/icons-material/Event';
import PeopleIcon from '@mui/icons-material/People';
import LabelIcon from '@mui/icons-material/Label';
import VideoCamIcon from '@mui/icons-material/Videocam';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';

export const GolfMenu = (props) => (
    <Menu {...props}>
        <DashboardMenuItem />
        <MenuItemLink to="/golfers" primaryText="Golf Pros" leftIcon={<GolfCourseIcon />}/>
        <MenuItemLink to="/events" primaryText="Events" leftIcon={<EventIcon />}/>
        <MenuItemLink to="/videos" primaryText="Videos" leftIcon={<VideoCamIcon />}/>
        <MenuItemLink to="/users" primaryText="Users" leftIcon={<PeopleIcon />}/>
        <MenuItemLink to="/options" primaryText="Options" leftIcon={<LabelIcon />}/>
    </Menu>
);
