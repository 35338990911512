// Util

export function sanitize_string( input ) {
    return input
        .replace( /[^%a-zA-Z0-9. _-]/g, '' ) // Odd chars to null
        .replace( /\s+/g, '-' ) // Space to -
        .replace( /-+/g, '-' ) // Multiple - to single
        .replace( /^[-]+/g, '' ) // Trim starting -
        .replace( /[-]+$/g, '' ); // Trim ending -
}

export const path = {
    basename: (input) => {
        const p = input.lastIndexOf( '/' );
        return input.substring( p+1 );
    }
};
