import {
    useNotify
} from 'react-admin';

import { useFormContext } from 'react-hook-form';
import { parse } from 'csv-parse/browser/esm';
import FileUploader from './file-uploader';
import { apiRequest } from '../../util/authorized-http-client';

const columns = [ 'last_name', 'first_name', 'email', 'code' ];
const parseArgs = { columns, from_line: 2 };

const csvFileToArray = async ( file ) => {
    const text = await file.text();

    return new Promise( (resolve, reject) => {
        parse( text, parseArgs, ( err, records ) => {
            if ( err ) {
                reject( err );
            }
            resolve( records );
        });
    });
};

const bulkCreateUsers = async ( records ) => {
    const { json } = await apiRequest( 'users/bulkcreate', {
        method: 'POST',
        body: JSON.stringify(records),
    });

    return json;
}


const isEmail = str => str.indexOf( '@' ) !== -1;


export const UserImportFromCSV = (props) => {
    const notify = useNotify();
    const { getValues, setValue } = useFormContext();
    
    const validateRows = async (rows) => {

        const result = {
            valid: [],
            invalid: [],
            exists: []
        };

        for( const i in rows ) {
            const row = rows[i];
            if ( isEmail( row.email ) ) {
                result.valid.push( row );
            } else {
                result.invalid.push( row );
            }
        }
        return result;
    }

    const handleFile = async (e) => {
        const rows = await csvFileToArray(e);
        const validator = await validateRows( rows );
        const target = 'references';

        notify( `Importing ${validator.valid.length} of ${rows.length} emails and assigning to video. Please wait.`, { autoHideDuration: 0 } );
        try {
            const result = await bulkCreateUsers( validator.valid );

            // Success, etc..
            const assigned = getValues( target );

            for ( const i in result.data ) {
                const rec = result.data[i];

                // Add new IDs
                if ( rec.id && !rec.error ) {
                    assigned.push( rec.id );
                }

                // Set value
                setValue( target, assigned, { shouldDirty: true } );
            }
        } catch( error ) {
            notify( error.message, { type: 'warning' } );
            console.log( error );
        }
    }


    return (
        <FileUploader label="Import Users" accept="text/csv" handleFile={handleFile} />
    );
}

export default UserImportFromCSV;